import '../fonts/stylesheet.css';
import '../css/app.css';
import 'css.gg';
import 'alpinejs'
const $ = require('jquery');
global.$ = global.jQuery = $;
const dragula = require('dragula');
global.dragula  = dragula;

CKEDITOR.replace('description');

global.setupInputFileWithReset = function(id) {
    let inputFile = $(id + ' input[type=file]');
    let inputCheckbox = $(id + ' input[type=checkbox]');
    let deleteBtn = $(id + ' .js-delete-btn');
    let fileNamePlaceholder = $(id + ' .js-filename');

    inputFile.on('change', (event) => {
        let fullPath = event.target.value;
        let fileName = fullPath.split(/(\\|\/)/g).pop();
        fileNamePlaceholder.text(fileName);
        inputCheckbox.prop('checked', false);
        return false;
    });

    deleteBtn.on('click', (event) => {
        inputFile.replaceWith(inputFile.val('').clone(true));
        fileNamePlaceholder.text('');
        inputCheckbox.prop('checked', true);
        return false;
    });
}